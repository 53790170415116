.carrear_img{
    height:400px;
    margin-top: 100px;
}
.main_cont{
    position: relative;
}
.content_lists{
    width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-top:20px;
    padding-bottom:20px;
    height:auto;
    font-family:'Roboto', serif;
    position: relative;
   
}
.product_cont{
     width:80%;
    margin-left:auto;
    margin-right:auto;
    padding-top:20px;
    padding-bottom:20px;
    height:auto;
    display:flex;
}
.product_p{
    width:50%;
    height:auto;
    padding-bottom:20px;
}
.cont_para_style{
    width:95%;
     margin-left:auto;
    margin-right:auto;
    height:auto;
     padding-bottom:20px;
    padding-top:20px;
    font-family: 'Roboto', serif;
    font-size:21px;
    text-indent:70px;
    text-align:justify;
}
.product_img{
    width:45%;
    margin-right:5%;
    height:100%;
    padding-top:90px;
}

.side_img{
    width:50%;
    margin-left:auto;
    margin-right:auto;
    height:300px;
    margin-top:20px;
    margin-bottom:20px;
}
.img_pro{
    width:80%;
     margin-left:auto;
    margin-right:auto;
    height:400px;
     padding-bottom:20px;
    padding-top:20px;
}
.key{
    width:60%;
     margin-left:auto;
    margin-right:auto;
    height:auto;
     padding-bottom:20px;
    padding-top:20px;
    font-family: 'Roboto', serif;
    font-size:18px;
    text-indent:33px;
}
.key ul{
    list-style-image: url('./img/arrow-58-16.png');
}