.about_index {
  width: 100%;
  /* height:780px; */
  display: flex;
}
.about_background_image {
  width: 80%;
  /* height: 100%; */
  background-image: url("../Sliderimg/hands.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.about_index_para {
  width: 55%;
  /* height: 100%; */
  background-color: rgba(41, 55, 80, 0.9);
  color: #fff;
  margin-left: -48%;
  padding-bottom: 30px;
}
.about_index_image {
  width: 35%;
  /* height: 100%; */
  margin: 0;
}
.about_index_image img {
  width: 100%;
  height: 50%;
  margin: 0;
  object-fit: cover;
}
.t1 {
  margin-left: 5%;
  margin-top: 20px;
}
.p1 {
  width: 90%;
  text-indent: 5%;
  margin-left: 5%;
  text-align: justify;
  font-family: "Roboto", serif;
  font-size: 18px;
}
.p1_d {
  width: 90%;
  text-indent: 5%;
  margin-left: 5%;
  text-align: justify;
  font-family: "Roboto", serif;
  font-size: 18px;
}
.rm {
  text-align: center;
  border: 2px solid black;
  text-decoration: none;
  padding: 5px 15px 5px 15px;
  color: black;
  font-weight: bold;
  margin-left: 40%;
}

@media screen and (max-width: 762px) {
  .about_index {
    width: 100%;
    /* height: 900px; */
    display: flex;
  }
  .about_index_image {
    display: none;
  }
  .about_index_para {
    width: 90%;
    /* height: 100%; */
    background-color: rgba(41, 55, 80, 0.9);
    color: #fff;
    margin-left: -90%;
  }
  .about_background_image {
    width: 100%;
    /* height:100%; */
    background-image: url("../Sliderimg/hands.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  .p1_d {
    /* display: none; */
  }
}
