.index_flip_part {
  position: relative;
  width: 100%;
  margin: 0;
  height: auto;
  /* background-image: url("../Sliderimg/gradient1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
  font-family: "Helvetica Neue", sans-serif;
}
.index_flip_main_part {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  background-color: rgba(41, 55, 66, 0.8);
  height: 100%;
  position: relative;
}

.index_flip_main_part h3 {
  position: relative;
  padding-bottom: 10px;
}
.index_flip_main_part h3::after {
  content: "";
  width: 0%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ff635e;
  transform-origin: left;
  transition: width 1s ease-in;
}
.index_flip_main_part h3:hover::after {
  width: 80%;
}

/* .index_flip_main_part::before{
    content:'';
    position:absolute;
    width:80%;
    height:5px;
    margin-left:10%;
    background-color:#FF635E;
    margin-top:9%;
    transform:scaleX(0.1);
    transform-origin:left;
    transition:transform 2s ease-in;
}
.index_flip_main_part:hover::before{
    transform:scaleX(1);
    transform-origin:left;
} */
.index_flip_main_part_head {
  margin-left: 10%;
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 22px;
  color: #ff635e;
}
.index_flip_main_part_1 {
  width: 80%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.index_flip_main_part_2 {
  width: 80%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.flip_cont {
  width: 30%;
  height: 80%;
  margin-top: 4%;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
.index_flip_sub_part_1 {
  position: relative;
}
.the_cards {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  transform-style: preserve-3d;
  transition: all 1s ease;
}
.the_cards:hover {
  transform: rotateY(180deg);
}
.front_card {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #ff635e;
  backface-visibility: hidden;
  background: transparent;
  color: #fff;
}
.back_card {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  background-color: #ff635e;
  color: black;
  transform: rotateY(180deg);
}
.front_card_cont {
  width: 90%;
  height: 90%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
  margin-top: 10%;
}
.back_card_cont {
  width: 90%;
  height: 90%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
  margin-top: 30%;
  color: #fff;
}
.flip_read {
  border: 2px solid #fff;
  padding: 4px 12px 4px 12px;
  text-decoration: none;
  color: #fff;
  margin-top: 40px;
}

@media screen and (max-width: 762px) {
  .index_flip_main_part_1 {
    width: 90%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    display: unset;
  }
  .index_flip_main_part_2 {
    width: 90%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    display: unset;
  }
  .flip_cont {
    width: 90%;
    height: 300px;
    margin-top: 4%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  /* .index_flip_main_part::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 5px;
    margin-left: 10%;
    background-color: #ff635e;
    margin-top: 15%;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 2s ease-in;
  } */
}
