.software_tools {
  font-family: "Roboto", serif;
  position: relative;
}
.tools_1 {
  width: 100%;
  height: auto;
  display: flex;
}
.res_tools {
  width: 50%;
  height: 150px;
  display: flex;
}
.img_logo {
  margin-left: 30px;
  width: 20%;
  height: 60%;
  transition: transform 0.2s;
  object-fit: contain;
}
.img_logo:hover {
  margin-top: 20px;
}

@media screen and (max-width: 762px) {
  .tools_1 {
    width: 100%;
    height: auto;
    display: unset;
  }
  .res_tools {
    width: 100%;
    height: 150px;
    display: flex;
  }
  .img_logo {
    margin-left: 0px;
    width: 25%;
    height: 60%;
  }
}
