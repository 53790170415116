* {
  margin: 0;
  padding: 0;
}
.footer {
  width: 100%;
  /* height: 400px; */
  background-color: #1f1e48d9;
  position: relative;
}
.footer_flex {
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.footer_flex_cont {
  width: 23%;
  /* height: 360px; */
  margin-left: auto;
  margin-right: auto;
  font-family: "Roboto", serif;
  line-height: 20px;
  padding-bottom: 15px;
}
.footer_flex_cont h2 {
  font-size: 22px;
  /* text-align: center; */
  color: #ff635e;
  padding-bottom: 20px;
  padding-top: 25px;
}
.footer_flex_cont ul {
  list-style: none;
}
.footer_flex_cont ul li {
  color: #ffffff;
  font-size: 17px;
  padding-bottom: 8px;
}
.footer_flex_cont ul li a {
  text-decoration: none;
  color: white;
  font-size: 17px;
}
.footer_flex_cont p {
  margin-left: 40px;
  text-indent: 30px;
  text-align: justify;
  font-size: 17px;
}
.footer_line {
  width: 95%;
  margin: auto;
  height: 3px;
  background-color: #ff635e;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.footer_copy {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: #ff635e;
  font-family: "Roboto", serif;
  margin-top: 12px;
  padding-bottom: 2px;
}

/* media query */
@media screen and (max-width: 762px) {
  .footer {
    width: 100%;
    /* height: auto; */
    padding-bottom: 40px;
  }
  .footer_flex {
    display: unset;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
  }
  .footer_flex_cont {
    width: 90%;
    margin-top: 20px;
    /* height: auto; */
    padding-bottom: 30px;
  }
  .footer_flex_cont h2 {
    text-align: center;
  }
  .footer_flex_cont ul {
    max-width: 273px;
    margin: auto;
  }
}
