.carrear_img{
    height:400px;
    margin-top: 100px;
}
.content_lists{
    width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-top:20px;
    padding-bottom:20px;
    height:auto;
    font-family:'Roboto', serif;
    position: relative;
   
}
.product_cont{
     width:80%;
    margin-left:auto;
    margin-right:auto;
    padding-top:20px;
    padding-bottom:20px;
    height:auto;
    display:flex;
}
.product_p{
    width:50%;
    height:auto;
    padding-bottom:20px;
}
.cont_para_style{
    width:95%;
     margin-left:auto;
    margin-right:auto;
    height:auto;
     padding-bottom:20px;
    padding-top:20px;
    font-family: 'Roboto', serif;
    font-size:21px;
    text-indent:70px;
    text-align:justify;
}
.banking{
    width:90%;
   margin-left:auto;
    margin-right:auto;
}
@media screen and (max-width : 762px){
    .carrear_img{
        height:250px;
    }
    .carrear_img .sli_img1{
      display: block;
      margin-left:20px;
      margin-right:20px;
      height:300px;
      width: 450px;
     
     }
    .product_cont{
         width:100%;
        margin-left:auto; 
        margin-right:auto;
        padding-top:20px;
        padding-bottom:20px;
        height:auto;
        display:unset;
    }
    .product_p{
        width:100%;
        height:auto;
        padding-bottom:20px;
    }
    .cont_para_style{
        width:90%;
         margin-left:auto;
        margin-right:auto;
        height:auto;
         padding-bottom:20px;
        padding-top:20px;
        font-family: 'Roboto', serif;
        font-size:18px;
        text-indent:30px;
        text-align:justify;
    }
    .product_img{
        width:100%;
        margin-right:0%;
        height:250px;
    }
    .img1{
        height:100%;
    }
    .img2{
        display:none;
    }
    .img3{
        display:block;
    }
    .key{
        width:80%;
         margin-left:auto;
        margin-right:auto;
        height:auto;
         padding-bottom:20px;
        padding-top:20px;
        font-family: 'EB Garamond', serif;
        font-size:18px;
        text-indent:30px;
    }
   
    .sli_img2{
       display:none;
    }
   
    .img_pro img{
        width:100%;
         margin-left:auto;
        margin-right:auto;
        height:300px;
         padding-bottom:20px;
        padding-top:20px;
    }
    .title2{
        text-align:center;
    }
    .side_img{
        width:100%;
        margin-left:auto;
        margin-right:auto;
        height:300px;
        margin-top:20px;
        margin-bottom:20px;
    }
   
}