.map_cont {
  width: 100%;
  position: relative;
}
.contact_main_cont {
  width: 90%;
  display: flex;
  padding-bottom: 20px;
}
.cont_info {
  /* width: 50%; */
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
}
.u_cont {
  width: 50%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  border-left: 5px solid #ff635e;
}
.contact_cont {
  list-style: none;
  font-size: 18px;
  line-height: 24px;
  font-family: "Roboto", serif;
}
.contact_cont li i {
  text-decoration: none;
  color: #ff635e;
}
.contact_title {
  color: #ff635e;
  margin-bottom: 20px;
}
.regtitle {
  width: 800px;
  background-color: #4f416f;
  margin: auto;
  color: #ffffff;
  padding: 10px 0px 10px 0px;
  text-align: center;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.regform {
  height: 500px;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* background-color: orange; */
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  margin-bottom: 40px;
}
.regform .title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}

.regform form .userdata {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
.regform form .userdata .databox {
  margin-bottom: 15px;
  width: calc(100% / 2 - 20px);
}
.regform form .databox span.details {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.userdata .databox input,
select {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 20px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.userdata .databox input:focus,
.userdata .databox input:valid {
  border-color: #185a9d;
}
.regform form .beginner-details .beginner-title {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.regform form .category {
  display: flex;
  width: 80%;
  margin: 14px 0;
  justify-content: space-between;
}
.regform form .category label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.regform form .category label .dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 10px;
  border: 5px solid transparent;
  transition: all 0.3s ease;
}
#dot-1:checked ~ .category label .one,
#dot-2:checked ~ .category label .two,
#dot-3:checked ~ .category label .three {
  background: #185a9d;
  border-color: #d9d9d9;
}
/* .regform form input[type="radio"]{
     display: none;
   } */
.regform form .button {
  height: 45px;
  margin: 35px 0;
  position: relative;
}
.regform form .button input {
  margin-bottom: 10px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #43cea2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #185a9d,
    #43cea2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #185a9d,
    #43cea2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.regform form .button input:hover {
  /* transform: scale(0.99); */
  background: #43cea2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #185a9d,
    #43cea2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #185a9d,
    #43cea2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media screen and (max-width: 762px) {
  .map_cont {
    width: 100%;
    position: relative;
  }
  .contact_main_cont {
    width: 100%;
    display: flex;
    padding-bottom: 20px;
  }
  .cont_info {
    /* width: 60%; */
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    /* padding: 20px; */
  }
  .u_cont {
    width: 100%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-align: center;
    border-left: 5px solid #ff635e;
  }
  .contact_cont {
    list-style: none;
    font-size: 18px;
    line-height: 24px;
    font-family: "Roboto", serif;
  }
  .contact_cont li i {
    text-decoration: none;
    color: #ff635e;
  }
  .contact_title {
    color: #ff635e;
  }
  .regtitle {
    width: 100%;
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    background-color: #4f416f;
    color: #ffffff;
    padding: 5px 0px 5px 0px;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    margin-top: 30px;
  }
  .regform {
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin-top: 30px; */
    /* background-color: orange; */
    padding: 25px 30px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }
  .regform .title {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
  }

  .regform form .userdata {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 12px 0;
  }
  .regform form .userdata .databox {
    margin-bottom: 10px;
    width: 100%;
  }
  .regform form .databox span.details {
    display: block;
    flex-direction: row;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  .userdata .databox input,
  select {
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 14px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: row;
  }
  .userdata .databox select option {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-top: 2px;
  }
  .userdata .databox input:focus,
  .userdata .databox input:valid {
    border-color: #185a9d;
  }
  .regform form .beginner-details .beginner-title {
    font-size: 10px;
    font-weight: 500;
  }
  .regform form .category {
    display: flex;
    width: 80%;
    margin: 14px 0;
    justify-content: space-between;
  }
  .regform form .category label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .regform form .category label .dot {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
    border: 5px solid transparent;
    transition: all 0.3s ease;
  }
  #dot-1:checked ~ .category label .one,
  #dot-2:checked ~ .category label .two,
  #dot-3:checked ~ .category label .three {
    background: #185a9d;
    border-color: #d9d9d9;
  }
  /* .regform form input[type="radio"]{
   display: none;
 } */
  .regform form .button {
    height: 45px;
    margin: 35px 0;
    position: relative;
  }
  .regform form .button input {
    margin-bottom: 10px;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: #43cea2; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #185a9d,
      #43cea2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #185a9d,
      #43cea2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .regform form .button input:hover {
    /* transform: scale(0.99); */
    background: #43cea2; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #185a9d,
      #43cea2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #185a9d,
      #43cea2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}
