.slider {
  width: 100%;
  height: 580px;
  position: relative;
  overflow: hidden;
  /* border: 5px solid orangered; */
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease infinite;
}

/* @media screen and (min-width: 600px) {
    .slide img {
      width: 100%;
      height: 100%;
    }
  }
   */
.slide img {
  /* // width: 100%; */
  height: 100%;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.content {
  position: absolute;
  top: 23rem;
  left: 20px;
  opacity: 0;
  width: 80%;
  color: #fff;
  padding: 3rem;
  background: rgba(0, 0, 0, 0.3);
  animation: slide-up 1s ease 0.5s;

  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 23rem;
  }
  100% {
    visibility: visible;
    top: 17rem;
  }
}

@media screen and (min-width: 762px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
  .content {
    width: 80%;
  }
}

.content > * {
  color: #fff;
  margin-bottom: 1rem;
}

.current .content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}
